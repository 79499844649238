.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  &>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.circularProgress {
  margin-bottom: 20px;
}

.message {
  &>* {
    margin-top: 20px;
  }
}