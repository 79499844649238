.container {
  margin: 0 0px 0 10px;

  button {
    width: 165px;
  }
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-height: 70%;
  transform: translate(-50%, -50%);
  overflow: auto;
  & > :first-child {
    margin-bottom: 10px;
  }
  //& > * {
  //  margin-bottom: 10px !important;
  //}

  .glossaryEntry {
    .linkContainer {
      margin-top: 40px !important;
    }
  }
}
