.container {
  width: 80px;

  & > div > div {
    height: 40px;
  }
}

.select > div {
  display: flex;
  align-items: center;
}

.flagEmoji {
  display: flex;
  justify-content: center;
  width: 100%;
}

[class*='flag:'], [class^='flag:'] {
  display: inline-block;
  background-size: cover;
  height: 1em;
  width: 1.5em;
  --CountryFlagIcon-height: 1em;
  height: var(--CountryFlagIcon-height);
  width: calc(var(--CountryFlagIcon-height) * 3 / 2);
  border: 1px solid lightgray;
  border-radius: 2px;
}

.flag\:PL {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cg fill='white'%3e%3cpath d='M0 85.337h512v341.326H0z'/%3e%3cpath d='M0 85.337h512V256H0z'/%3e%3c/g%3e%3cpath fill='%23D80027' d='M0 256h512v170.663H0z'/%3e%3c/svg%3e")
}

.flag\:GB {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='white' d='M0 85.333h512V426.67H0z'/%3e%3cpath fill='%23D80027' d='M288 85.33h-64v138.666H0v64h224v138.666h64V287.996h224v-64H288z'/%3e%3cpath d='M393.785 315.358 512 381.034v-65.676zm-82.133 0L512 426.662v-31.474l-143.693-79.83zm146.982 111.304-146.982-81.664v81.664z' fill='%230052B4'/%3e%3cpath fill='white' d='M311.652 315.358 512 426.662v-31.474l-143.693-79.83z'/%3e%3cpath fill='%23D80027' d='M311.652 315.358 512 426.662v-31.474l-143.693-79.83z'/%3e%3cpath d='M90.341 315.356 0 365.546v-50.19zm110.007 14.154v97.151H25.491z' fill='%230052B4'/%3e%3cpath fill='%23D80027' d='M143.693 315.358 0 395.188v31.474l200.348-111.304z'/%3e%3cpath d='M118.215 196.634 0 130.958v65.676zm82.133 0L0 85.33v31.474l143.693 79.83zM53.366 85.33l146.982 81.664V85.33z' fill='%230052B4'/%3e%3cpath fill='white' d='M200.348 196.634 0 85.33v31.474l143.693 79.83z'/%3e%3cpath fill='%23D80027' d='M200.348 196.634 0 85.33v31.474l143.693 79.83z'/%3e%3cpath d='M421.659 196.636 512 146.446v50.19zm-110.007-14.154V85.331h174.857z' fill='%230052B4'/%3e%3cpath fill='%23D80027' d='M368.307 196.634 512 116.804V85.33L311.652 196.634z'/%3e%3c/svg%3e")
}
