.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  svg {
    margin: 0 4px 0 4px;
  }

  .cookiesNotice {
    border: 1px solid gray;
    border-radius: 8px;
    background-color: lightgray;
    padding: 10px;
    font-weight: 500;
    font-style: italic;
  }

  .closeButton {
    margin-top: 10px !important;
    border: 1px solid gray !important;
  }
}

// for screens lower than 600px introduce fixed height and scroll
@media (max-height: 600px) {
  .modalBox {
    width: 80%;
    height: auto;
    max-height: 90%;
    overflow: scroll;
  }
}

@media (max-width: 1000px) {
  .modalBox {
    width: 70%;
    height: auto;
    max-height: 90%;
    overflow: scroll;
  }
}
