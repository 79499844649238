.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  max-width: 500px;
  min-width: 300px;
  max-height: 60%;
  transform: translate(-50%, -50%);
  overflow: auto;

  & > *:not(:first-child) {
    margin-top: 10px;
  }

  .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .textField {
      width: 140px;
    }
    button {
      margin-top: 8px;
    }
  }
}


